import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../home/faq/faq.css";

function Franchise_faq() {
    const { t } = useTranslation();
    const [visibleSections, setVisibleSections] = useState({});
    const toggleSectionVisibility = (id) => {
        setVisibleSections(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    
    return (
        <>
            <div className="franchise_faq">
            <div className="container-fluid" id="faq">
                <div className="row">
                    <div className="col-lg-6 faq_left">
                        <h2>{t("Frequently asked questions")}</h2>
                    </div>
                    <div className="col-lg-6 faq_right">
                        <div>
                            <section>
                                <h4 onClick={() => toggleSectionVisibility('faq1')} aria-expanded={visibleSections['faq1'] ? "true" : "false"}>
                                    {t("How do I become a CHARGE franchisee?")}
                                </h4>
                                <p style={{ maxHeight: visibleSections['faq1'] ? '500px' : '0px', overflow: 'hidden' }}>
                                    {t("Becoming a CHARGE franchisee involves a few key steps:")} <br /> <br />
                                    <span>{t("1. Initial Inquiry")} : </span> {t("Fill out the franchise inquiry form on our website to express your interest.")} <br /> <br />
                                    <span>{t("2. Receive Franchise Information")} : </span> {t("Once we receive your inquiry, we will send you a detailed franchise kit that includes investment requirements, support details, and operational guidelines.")} <br /> <br />
                                    <span>{t("3. Submit Your Application")} : </span> {t("Complete and submit your application along with any required documents.")} <br /> <br />
                                    <span>{t("4. Interview")} : </span> {t("Participate in an interview to discuss your application and potential fit with CHARGE's franchise model.")} <br /> <br />
                                    <span>{t("5. Approval and Training")} : </span> {t("If your application is approved, you'll undergo comprehensive training covering all aspects of running a CHARGE franchise.")} <br /> <br />
                                    <span>{t("6. Launch Your Business")} : </span> {t("With support from CHARGE, set up your operations and start your journey as a CHARGE franchisee")}
                                </p>
                            </section>
                            <section>
                                <h4 onClick={() => toggleSectionVisibility('faq2')} aria-expanded={visibleSections['faq2'] ? "true" : "false"}>
                                    {t("What support does CHARGE provide to its franchisees?")}
                                </h4>
                                <p style={{ maxHeight: visibleSections['faq2'] ? '500px' : '0px', overflow: 'hidden' }}>
                                    {t("CHARGE is committed to the success of our franchisees and offers robust support, including:")} <br /> <br />
                                    <span>{t("Training Programs")} : </span> {t("Access to our marketing materials and guidance on local advertising to help you attract and retain customers.")} <br /> <br />
                                    <span>{t("Marketing and Branding")} : </span> {t("Use the search feature to find scooters in specific areas or near landmarks.")} <br /> <br />
                                    <span>{t("Operational Support")} : </span> {t("Ongoing support from our experienced team to ensure smooth operations and to address any challenges")}
                                    <span>{t("Technology and Maintenance")} : </span> {t("Advanced software for managing bookings, payments, and scooter maintenance.")}
                                </p>
                            </section>
                            <section>
                                <h4 onClick={() => toggleSectionVisibility('faq3')} aria-expanded={visibleSections['faq3'] ? "true" : "false"}>
                                    {t("How do I manage my inventory of scooters?")}
                                </h4>
                                <p style={{ maxHeight: visibleSections['faq3'] ? '500px' : '0px', overflow: 'hidden' }}>
                                    {t("Managing your scooter inventory effectively is crucial for the success of your franchise:")} <br /> <br />
                                    <span>{t("1. Tracking System")} : </span> {t("Utilize CHARGE's proprietary software to track scooter locations, usage patterns, and battery levels in real-time.")} <br /> <br />
                                    <span>{t("2. Regular Inspections")} : </span> {t("Conduct regular inspections to ensure each scooter is in optimal condition and safe to use.")}<br /> <br />
                                    <span>{t("3. Demand-Based Distribution")} : </span> {t("3. Analyze usage data to optimize the distribution of scooters across different locations, ensuring high availability where and when needed.")}
                                </p>
                            </section>
                            <section>
                                <h4 onClick={() => toggleSectionVisibility('faq2')} aria-expanded={visibleSections['faq2'] ? "true" : "false"}>
                                    {t("What are the best practices for maintaining scooters?")}
                                </h4>
                                <p style={{ maxHeight: visibleSections['faq2'] ? '500px' : '0px', overflow: 'hidden' }}>
                                    {t("Proper maintenance is key to keeping your fleet operational and safe:")} <br /> <br />
                                    <span>{t("Daily Checks")} : </span> {t("Perform daily checks for any visible damage or issues with the scooters.")} <br /> <br />
                                    <span>{t("Regular Servicing")} : </span> {t("Follow a regular servicing schedule to check and maintain essential components like brakes, tires, and batteries.")} <br /> <br />
                                    <span>{t("Immediate Repairs")} : </span> {t("Address any damages or issues immediately to prevent them from worsening.")}
                                    <span>{t("Cleanliness")} : </span> {t("Ensure scooters are clean and presentable at all times, which helps maintain brand image and customer satisfaction.")}
                                </p>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    );
}

export default Franchise_faq;
