import Terms_hero from "../components/terms/terms_hero/terms_hero";
import Terms_use from "../components/terms/terms_use/terms_use";

function Terms() {
    return(
        <>
            <Terms_hero />
            <Terms_use />
        </>
    );
}

export default Terms;