import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Down_butt from "../../general/down_butt/down_butt";
import { Link } from "react-router-dom";
import "../../driver/driver_call/driver_call.css";
import "./taxi_benefits.css";

export default function Taxi_benefits() {
    const { t } = useTranslation();
    const [visibleSections, setVisibleSections] = useState({});
    const toggleSectionVisibility = (id) => {
        setVisibleSections(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    return(
        <>
            <div className="taxi_benefits_main">
                <div className="tbm_shouter">
                    <h2>{t("Instant, Reliable and Stylish")} </h2>
                    <h4>{t("Why wait? With just a few taps in our app, a premium vehicle will be curbside in minutes, ready to whisk you away to your destination. Our cars are more than just a means to an end—they’re a mobile haven. Enjoy immaculate interiors, complimentary Wi-Fi, and just the right touch of luxury to elevate your ride from mundane to memorable.")} </h4>
                </div>
                <div className="container-fluid amm_services">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 amm_services_item tbm_photo_text">
                            <p className="tbm_pt_p">{t("Driven by Excellence")}</p>
                            <p>{t("Our drivers are at the heart of CHARGE Rides. Handpicked for their professionalism and local knowledge, they are not just drivers but guardians of your comfort and time. Trained to maintain the highest standards of service, they ensure that every journey you take with us is safe, clean, and precisely to your liking.")}</p>
                        </div>
                        <div className="col-lg-6 col-md-12 amm_services_item">
                            <img src="/driver_imgs/driver1.png" alt="amm_1" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12 amm_services_item">
                            <img src="/driver_imgs/driver3.png" alt="amm_2" />
                        </div>
                        <div className="col-lg-6 col-md-12 amm_services_item tbm_photo_text">
                            <p className="tbm_pt_p">{t("Eco-Friendly Journeys")}</p>
                            <p>{t("Ride with us and ride with purpose. CHARGE is committed to reducing our carbon footprint, one journey at a time. Our fleet includes environmentally friendly vehicles designed to keep the air as clean as our service. By choosing CHARGE Rides, you’re not just getting to your destination—you’re helping reach a greener future.")}</p>
                        </div>
                    </div>
                </div>
                <div className="tbm_shouter">
                    <h2>{t("Ride Smarter, Not Harder")} </h2>
                    <h4>{t("Download the CHARGE app today and transform the way you travel. Quick setup, easy booking, and transparent pricing — all in one app. Your next journey awaits, and it’s going to be spectacular. With CHARGE Rides, every trip is a statement. Are you ready to make yours?")} </h4>
                </div>
                <div className="container-fluid driver_call_main">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 dc_left">
                            <div>
                                <h2>&#11044; {t("Sign up in Charge app and get your ride today!")} </h2>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 dc_right">
                            <div>
                                <Down_butt />
                                <p>{t("Download and Drive")} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tbm_shouter tbm_support">
                    <h2>{t("Always Here for You")} </h2>
                    <h4>{t("Our customer support team is just as responsive as our service. Available around the clock, they’re here to ensure your ride is seamless from start to finish. With CHARGE, you’re never riding alone.")} </h4>
                    <Link to="/support"> Contact support </Link>
                </div>
                {/* <div className="container-fluid" id="faq">
                <div className="row">
                    <div className="col-lg-6 faq_left">
                        <h2>{t("Frequently asked questions")}</h2>
                    </div>
                    <div className="col-lg-6 faq_right">
                        <div>
                            <section>
                                <h4 onClick={() => toggleSectionVisibility('faq1')} aria-expanded={visibleSections['faq1'] ? "true" : "false"}>
                                    {t("Can I rent multiple scooters?")}
                                </h4>
                                <p style={{ height: visibleSections['faq1'] ? '40px' : '0px', overflow: 'hidden' }}>
                                    {t("Yes, you can press the \"Add another\" button after starting a ride to add more. ")}
                                </p>
                            </section>
                            <section>
                                <h4 onClick={() => toggleSectionVisibility('faq2')} aria-expanded={visibleSections['faq2'] ? "true" : "false"}>
                                    {t("Can I pause my ride?")}
                                </h4>
                                <p style={{ height: visibleSections['faq2'] ? '90px' : '0px', overflow: 'hidden' }}>
                                    {t("Yes, you can press the \"stop ride\" at the bottom of your screen. \"Pause ride\" option will appear. ")}
                                    {t("The vehicle will be locked and you can leave it until you want to continue your ride. ")}
                                    {t("Pausing your ride costs per minute and may vary depending on the location. ")}
                                </p>
                            </section>
                            <section>
                                <h4 onClick={() => toggleSectionVisibility('faq3')} aria-expanded={visibleSections['faq3'] ? "true" : "false"}>
                                    {t("How much does it cost to ride?")}
                                </h4>
                                <p style={{ height: visibleSections['faq3'] ? '50px' : '0px', overflow: 'hidden' }}>
                                    {t("There is a starting fee to unlock the vehicle as well as a fee per minute used. ")}
                                    {t("Press on a scooter in the app to see the pricing. It may vary between locations. ")}
                                </p>
                            </section>
                            <section>
                                <h4 onClick={() => toggleSectionVisibility('faq4')} aria-expanded={visibleSections['faq4'] ? "true" : "false"}>
                                    {t("How do I start riding?")}
                                </h4>
                                <p style={{ height: visibleSections['faq4'] ? '70px' : '0px', overflow: 'hidden' }}>
                                    {t("Find a Hopp vehicle on the map, unlock it by tapping the \"Ride\" button, then either scan the QR code or enter the number below the QR code. ")}
                                </p>
                            </section>
                        </div>
                    </div>
                </div>
            </div> */}
            </div>
        </>
    );
}