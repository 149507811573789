import { useTranslation } from "react-i18next";
import Down_butt from "../../general/down_butt/down_butt";
import "./driver_call.css";

export default function Driver_call() {
    const { t } = useTranslation();

    return(
        <div className="container-fluid driver_call_main">
            <div className="row">
                <div className="col-lg-6 col-sm-12 dc_left">
                    <div>
                        <h2>&#11044; {t("Sign up in Charge Driver app and start driving today!")} </h2>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 dc_right">
                    <div>
                        <Down_butt />
                        <p>{t("Download and Drive")} </p>
                    </div>
                </div>
            </div>
        </div>
    );
}