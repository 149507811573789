import About_hero from "../components/about/about_hero/about_hero";
import About_mission from "../components/about/about_mission/about_mission";

function About() {
    return(
        <>
            <About_hero />
            <About_mission />
        </>
    );
}

export default About;