import { useTranslation } from "react-i18next";
import "./driver_shouter.css";

function Driver_shouter() {
    const { t } = useTranslation();

    return(
        <>
            <h2 className="driver_shouter_h2">{t("Join CHARGE - Drive into the Future of Ride-Hailing!")} </h2>
            <div className="container-fluid driver_shouter_main">
                <div className="row">
                    <div className="col-lg-6 col-sm-12 ds_left">
                        <div>
                            <img src="/driver_imgs/driver3.png" alt="driver3" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 ds_right">
                        <div>
                            <h4>{t("Are you a skilled driver looking for a flexible way to earn more? CHARGE, the leading ride and hail app, is expanding our team and we want YOU on board. ")} </h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Driver_shouter;