import Driver_hero from "../components/driver/driver_hero/driver_hero";
import ContactForm from "../components/general/contact_form/contactForm";
import Driver_shouter from "../components/driver/driver_shouter/driver_shouter";
import Driver_perks from "../components/driver/driver_perks/driver_perks";
import Driver_call from "../components/driver/driver_call/driver_call";
import Driver_faq from "../components/driver/driver_faq/driver_faq";
import { useTranslation } from 'react-i18next';


function Driver() {
    const { t } = useTranslation();

    return(
        <>
            <Driver_hero />
            <Driver_shouter />
            <Driver_perks />
            <Driver_call />
            <div className="driver_form">
                <ContactForm formHeading={t("Ready to rev up your earning potential with CHARGE?")}> 
                    <p>{t("Signing up is easy – fill in the form and start your journey!")}</p>
                    <p>{t("Drive with CHARGE. Drive with Pride. Drive Towards a Better Tomorrow.")}</p>
                </ContactForm>
            </div>
            {/* <Driver_faq /> */}
        </>
    );
}

export default Driver;


