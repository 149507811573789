import Privacy_hero from "../components/privacy/privacy_hero/privacy_hero";
import Privacy_policy from "../components/privacy/privacy_policy/privacy_policy";

function Privacy() {
    return(
        <>
            <Privacy_hero />
            <Privacy_policy />
        </>
    );
}

export default Privacy;