import { useTranslation } from "react-i18next";
import "./driver_perks.css";

function Driver_perks() {
    const { t } = useTranslation();

    return(
        <div className="container-fluid driver_perks_main">
            <div className="row">
                <div className="col-lg-6 col-sm-12 dp_left">
                    <div>
                        <h2>{t("Here’s why driving with CHARGE is the smart move:")} </h2>
                        <ul>
                            <li>
                                <h6>{t("Higher Earnings, Lower Fees:")}</h6>
                                <p>{t("Enjoy competitive commissions and bonuses, putting more money in your pocket with each ride. Our priority is your profit!")}</p>
                            </li>
                            <li>
                                <h6>{t("Absolute Flexibility:")}</h6>
                                <p>{t("Your car, your rules. Drive full-time or just a few hours a week, whenever it suits you. With CHARGE, you’re in control of your schedule.")}</p>
                            </li>
                            <li>
                                <h6>{t("Instant Pay:")}</h6>
                                <p>{t("Why wait for your hard-earned money? With our Instant Pay feature, you can transfer your earnings to your bank account anytime you want.")}</p>
                            </li>
                            <li>
                                <h6>{t("Safe and Secure:")}</h6>
                                <p>{t("Your safety is our top priority. Enjoy peace of mind with our in-app safety features, including SOS button, ride tracking, and 24/7 support.")}</p>
                            </li>
                            <li>
                                <h6>{t("A Thriving Community:")}</h6>
                                <p>{t("Be part of a supportive and dynamic driver community. Get tips, attend exclusive events, and grow with us.")}</p>
                            </li>
                            <li>
                                <h6>{t("Easy to Use:")}</h6>
                                <p>{t("Our app is designed with drivers in mind - intuitive navigation, less wait time, and more rides!")}</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 dp_right">
                    <div>
                        <img src="/driver_imgs/driver2.png" alt="driver2" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Driver_perks;