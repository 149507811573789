import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import To_form from "../../general/to_form/to_form";
import "../../franchise/franchise_shouter/franchise_shouter.css";
import "./locations_places.css";

export default function Locations_places() {
    const { t } = useTranslation();

    const [cityCount, setCityCount] = useState(0);
        useEffect(() => {
            const cityTitles = document.querySelectorAll('.lpm_city_title');
            setCityCount(cityTitles.length);
        }, []);

    const [visibleCities, setVisibleCities] = useState({});
    const toggleCityVisibility = (id) => {
        setVisibleCities(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };
    const isVisible = (id) => visibleCities[id] || false; 

    return(
        <>
            <div className="locations_places_main">
                <div className="lpm_item">
                    <div className="lpm_item_title">
                        <h4>{t("Latvia")}</h4>
                        <h6>{cityCount} {t("cities")}</h6>
                    </div>
                    <div className="lpm_item_array">
                        <div className="lpm_city">
                            <div className="lpm_city_title" onClick={() => toggleCityVisibility('Adazi')} aria-expanded={isVisible('Adazi') ? "true" : "false"}>
                                <h3>Ādaži</h3>
                            </div>
                            <div className="lpm_city_content" style={{ maxHeight: isVisible('Adazi') ? '1000px' : '0px', overflow: 'hidden' }}>
                                <div>
                                    <p>{t("Ādaži is a town in Pierīga, on the left bank of the Gauja river. The town is the administrative center of the Ādaži Municipality. It is located by the highway A1, 21.6 km from the center of Riga. Latvia's longest river, the Gauja, flows along the Ādaži River, and its tributary Vējupe divides the town into two parts.")}</p>
                                </div>
                                <section>
                                    <div className="lpm_cc_prices">
                                        <h5>{t("COST TO RIDE:")} </h5>
                                        <div>
                                            <div>
                                                <h5>{t("TO START")}</h5>
                                                <h6>0.8 €</h6>
                                            </div>
                                            <div>
                                                <h5>{t("PER MINUTE")}</h5>
                                                <h6>0.2 €</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lpm_cc_services">
                                        <h5>{t("SERVICES PROVIDED:")} </h5>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-scooter" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M9 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-.39l1.4 7a2.5 2.5 0 1 1-.98.195l-.189-.938-2.43 3.527A.5.5 0 0 1 9.5 13H4.95a2.5 2.5 0 1 1 0-1h4.287l2.831-4.11L11.09 3H9.5a.5.5 0 0 1-.5-.5M3.915 12a1.5 1.5 0 1 0 0 1H2.5a.5.5 0 0 1 0-1zm8.817-.789A1.499 1.499 0 0 0 13.5 14a1.5 1.5 0 0 0 .213-2.985l.277 1.387a.5.5 0 0 1-.98.196z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="lpm_city_image" style={{ maxHeight: isVisible('Adazi') ? '1000px' : '0px', overflow: 'hidden' }}>
                                <img src="/slider/slider1.png" alt="city" />
                            </div>
                        </div>
                        <div className="lpm_city">
                            <div className="lpm_city_title" onClick={() => toggleCityVisibility('Carnikava')} aria-expanded={isVisible('Carnikava') ? "true" : "false"}>
                                <h3>Carnikava</h3>
                            </div>
                            <div className="lpm_city_content" style={{ maxHeight: isVisible('Carnikava') ? '1000px' : '0px', overflow: 'hidden' }}>
                                <div>
                                    <p>{t("Carnikava, previously Sānkaule, is a village and the center of Carnikava Parish in Ādaži Municipality, Latvia. It's located 25 km north from Riga at the mouth of the Gauja River. Carnikava had 4689 residents as of January 2020, making it the largest village by population in Latvia.")}</p>
                                </div>
                                <section>
                                    <div className="lpm_cc_prices">
                                        <h5>{t("COST TO RIDE:")} </h5>
                                        <div>
                                            <div>
                                                <h5>{t("TO START")}</h5>
                                                <h6>0.8 €</h6>
                                            </div>
                                            <div>
                                                <h5>{t("PER MINUTE")}</h5>
                                                <h6>0.2 €</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lpm_cc_services">
                                        <h5>{t("SERVICES PROVIDED:")} </h5>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-scooter" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M9 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-.39l1.4 7a2.5 2.5 0 1 1-.98.195l-.189-.938-2.43 3.527A.5.5 0 0 1 9.5 13H4.95a2.5 2.5 0 1 1 0-1h4.287l2.831-4.11L11.09 3H9.5a.5.5 0 0 1-.5-.5M3.915 12a1.5 1.5 0 1 0 0 1H2.5a.5.5 0 0 1 0-1zm8.817-.789A1.499 1.499 0 0 0 13.5 14a1.5 1.5 0 0 0 .213-2.985l.277 1.387a.5.5 0 0 1-.98.196z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="lpm_city_image" style={{ maxHeight: isVisible('Carnikava') ? '1000px' : '0px', overflow: 'hidden' }}>
                                <img src="/slider/slider2.png" alt="city" />
                            </div>
                        </div>
                        <div className="lpm_city">
                            <div className="lpm_city_title" onClick={() => toggleCityVisibility('Saulkrasti')} aria-expanded={isVisible('Saulkrasti') ? "true" : "false"}>
                                <h3>Saulkrasti</h3>
                            </div>
                            <div className="lpm_city_content" style={{ maxHeight: isVisible('Saulkrasti') ? '1000px' : '0px', overflow: 'hidden' }}>
                                <div>
                                    <p>{t("Saulkrasti is a town in Latvia on the east coast of the Gulf of Riga and the capital of the Saulkrasti municipality. Made up of a number of historic fishing settlements, the town stretches for some 17 km from the Inčupe river in the south to the village of Skulte in the north.")}</p>
                                </div>
                                <section>
                                    <div className="lpm_cc_prices">
                                        <h5>{t("COST TO RIDE:")} </h5>
                                        <div>
                                            <div>
                                                <h5>{t("TO START")}</h5>
                                                <h6>0.8 €</h6>
                                            </div>
                                            <div>
                                                <h5>{t("PER MINUTE")}</h5>
                                                <h6>0.2 €</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lpm_cc_services">
                                        <h5>{t("SERVICES PROVIDED:")} </h5>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-scooter" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M9 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-.39l1.4 7a2.5 2.5 0 1 1-.98.195l-.189-.938-2.43 3.527A.5.5 0 0 1 9.5 13H4.95a2.5 2.5 0 1 1 0-1h4.287l2.831-4.11L11.09 3H9.5a.5.5 0 0 1-.5-.5M3.915 12a1.5 1.5 0 1 0 0 1H2.5a.5.5 0 0 1 0-1zm8.817-.789A1.499 1.499 0 0 0 13.5 14a1.5 1.5 0 0 0 .213-2.985l.277 1.387a.5.5 0 0 1-.98.196z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="lpm_city_image" style={{ maxHeight: isVisible('Saulkrasti') ? '1000px' : '0px', overflow: 'hidden' }}>
                                <img src="/slider/slider3.png" alt="city" />
                            </div>
                        </div>
                        <div className="lpm_city">
                            <div className="lpm_city_title" onClick={() => toggleCityVisibility('Sigulda')} aria-expanded={isVisible('Sigulda') ? "true" : "false"}>
                                <h3>Sigulda</h3>
                            </div>
                            <div className="lpm_city_content" style={{ maxHeight: isVisible('Sigulda') ? '1000px' : '0px', overflow: 'hidden' }}>
                                <div>
                                    <p>{t("Sigulda is on a picturesque stretch of the primeval Gauja river valley. Because of the reddish Devonian sandstone which forms steep rocks and caves on both banks of the river, Sigulda has been called the \"Switzerland of Vidzeme\".")}</p>
                                </div>
                                <section>
                                    <div className="lpm_cc_prices">
                                        <h5>{t("COST TO RIDE:")} </h5>
                                        <div>
                                            <div>
                                                <h5>{t("TO START")}</h5>
                                                <h6>0.8 €</h6>
                                            </div>
                                            <div>
                                                <h5>{t("PER MINUTE")}</h5>
                                                <h6>0.2 €</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lpm_cc_services">
                                        <h5>{t("SERVICES PROVIDED:")} </h5>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-scooter" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M9 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-.39l1.4 7a2.5 2.5 0 1 1-.98.195l-.189-.938-2.43 3.527A.5.5 0 0 1 9.5 13H4.95a2.5 2.5 0 1 1 0-1h4.287l2.831-4.11L11.09 3H9.5a.5.5 0 0 1-.5-.5M3.915 12a1.5 1.5 0 1 0 0 1H2.5a.5.5 0 0 1 0-1zm8.817-.789A1.499 1.499 0 0 0 13.5 14a1.5 1.5 0 0 0 .213-2.985l.277 1.387a.5.5 0 0 1-.98.196z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="lpm_city_image" style={{ maxHeight: isVisible('Sigulda') ? '1000px' : '0px', overflow: 'hidden' }}>
                                <img src="/slider/slider4.png" alt="city" />
                            </div>
                        </div>
                    </div>      
                </div>
            </div>
            <div className="lpm_text">
                <div className="fsm_text">
                    <div>
                        <p>{t("Didn't find your city on the list? Become a part of our franchise community and make your city more sustainable. Leave your request here, and we will contact you soon.")} </p>
                    </div>
                    <div>
                        <To_form />
                    </div>
                </div>
            </div>
        </>
    );
}