import { useTranslation } from "react-i18next";
import "../../driver/driver_shouter/driver_shouter.css";


export default function Franchise_section() {
    const {t} = useTranslation();

    return(
        <div className="franchise_section">
        <h2 className="driver_shouter_h2">{t("Elevate Your Entrepreneurial Journey with CHARGE Scooter Sharing!")} </h2>
            <div className="container-fluid driver_shouter_main">
                <div className="row">
                    <div className="col-xl-6 col-lg-12 ds_left">
                        <div>
                            <img src="/driver_imgs/driver3.png" alt="driver3" />
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 ds_right">
                        <div>
                            <h4>{t("Are you ready to steer towards a future filled with growth, innovation, and sustainability? CHARGE offers you the unique opportunity to own a business that not only leads the market in eco-friendly urban mobility but also drives positive change in your community. With our advanced, user-friendly scooters, comprehensive franchise support, and a commitment to green transportation, your path to success is illuminated with every ride. Dare to lead the way in sustainable urban travel.")} </h4>
                        </div>
                    </div>
                </div>
            </div>
            <h2 className="franch_shouter_h2">{t("Join CHARGE today and make a lasting impact!")}</h2>
        </div>
    );
}