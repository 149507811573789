import { useTranslation } from "react-i18next";
import { useState } from "react";
import "./terms_use.css";

export default function Terms_use() {
    const {t} = useTranslation();
    const [expansionStates, setExpansionStates] = useState({});
    const toggleExpand = (id) => {
        setExpansionStates(prev => ({ ...prev, [id]: !prev[id] }));
    };

    return(
        <div className="terms_main">
            <div>
                <h6>{t("These Terms of Use govern the provision of CHARGE services according to the terms below, which constitute a legal agreement between SIA “ORGANIC BALTICS”, reg. No. 40103570161, as the Lessor and you as the Lessee. By agreeing to this Agreement, you confirm that you have familiarized yourself with its content, the terms are understandable, and you commit to adhere to them.")} </h6>
            </div>
            <div className="tou_list">
                <section>
                    <h4 onClick={() => toggleExpand("termsUsed")}>{t("Terms Used")}</h4>
                    <ul style={{ maxHeight: expansionStates["termsUsed"] ? '1000px' : '0' }}>
                        <li>{t("1.1. Agreement – the agreement between the Lessee and the Lessor.")}</li>
                        <li>{t("1.2. Account – a user account in the CHARGE Application, which provides the Lessee access to Services and includes information about the Lessee’s transactions within the Application.")} </li>
                        <li>{t("1.3. Privacy Policy – the privacy policy located in the CHARGE Application and on the website. ")}</li>
                        <li>{t("1.4. Lessee – a user of the CHARGE Application who has registered as a user and uses CHARGE Services.")}</li>
                        <li>{t("1.5. Services – the CHARGE Application, Electric Scooter rental, and other related services listed in the Application, available to the Lessee either separately or together with Vehicle or Electric Scooter rental services.")}</li>
                        <li>{t("1.6. Discount Code – a code that provides the Lessee with an opportunity to reduce the Service fee by applying a discount. Additional terms for using discount codes may be applied by the Lessor.")}</li>
                        <li>{t("1.7. CHARGE Application – a mobile application owned by the Lessor, available in the App Store and Google Play Store.")}</li>
                        <li>{t("1.8. Scooter – an electric scooter owned by the Lessor, bearing CHARGE branding.")}</li>
                        <li>{t("1.9. Scooter Rental Service – the Lessor’s provision of a Scooter for use by the Lessee for a fee in accordance with the CHARGE Application, these Terms, and the Privacy Policy. 1.10. Green Zone – an area marked within the CHARGE Application.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("generalTerms")}>{t("General Terms")}</h4>
                    <ul style={{ maxHeight: expansionStates["generalTerms"] ? '1000px' : '0' }}>
                        <li>{t("2.1. These terms are binding for all Lessees from the moment the Lessee creates an Account using the Lessor’s Application.")}</li>
                        <li>{t("2.2. The terms are available to the Lessee in the Lessor’s Application both before and after the creation of the Account, and throughout the use of the Account. By using a Scooter, the Lessee affirms their agreement to these Terms.")}</li>
                        <li>{t("2.3. For the improvement of Services, the Lessor has the right to make unilateral amendments to these Terms. Amendments become binding for the Lessee upon their publication. Changes will be published on the Lessor’s website and in the Application. Lessees are encouraged to familiarize themselves with the current version of these Terms before each ride.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("lesseeReg")}>{t("Lessee’s Registration in the CHARGE Application")}</h4>
                    <ul style={{ maxHeight: expansionStates["lesseeReg"] ? '1000px' : '0' }}>
                        <li>{t("3.1. The Lessee registers in the CHARGE Application by downloading it from the App Store or Google Play Store and completing the registration steps.")}</li>
                        <li>{t("3.2. The Lessor confirms the Lessee's registration in the CHARGE Application by sending a text message with an access code to the mobile phone number provided by the Lessee. The Lessee’s Account is considered created once the Lessee has entered the access code received from the Lessor into the CHARGE Application. The Lessee is prohibited from providing incorrect or third-party information.")}</li>
                        <li>{t("3.3. Upon first-time authorization by entering their mobile phone number and the access code received via text from the Lessor, the Lessee is deemed to have expressed a free, unambiguous, and direct will, agreeing to the Terms and committing to adhere to them, performing all subsequent actions using the CHARGE Application. The Terms are considered binding for all the Lessee’s subsequent actions in the Application, including transactions, confirmations, uploading photos, etc., from the moment of confirming the Terms.")}</li>
                        <li>{t("3.4. For all actions in the CHARGE Application, the specified mobile phone number is used as the Lessee’s identifier. One Account is created per mobile phone number. By entering their phone number in the CHARGE Application, the Lessee confirms their identity.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("scooterRental")}>{t("Electric Scooter Rental")}</h4>
                    <ul style={{ maxHeight: expansionStates["scooterRental"] ? '1000px' : '0' }}>
                        <li>{t("4.1. The Lessee informs the Lessor of their desire to rent a Scooter using the CHARGE Application.")} </li>
                        <li>{t("4.2. In the Application, the Lessee selects the Scooter they wish to rent. If the Scooter selected by the Lessee is not available, the Lessor rejects the rental of the selected Scooter.")}</li>
                        <li>{t("4.3. The provision of the Scooter Rental Service to the Lessee begins at the moment the Lessee has scanned the QR code or entered it and activated the Scooter in the CHARGE Application.")}</li>
                        <li>{t("4.4. The end of the Scooter Rental Service is considered to be the moment when the Lessee has confirmed the completion of the Scooter rental in the Application and, using the Application, has uploaded and sent a photo of the Scooter placed in the Green Zone to the Lessor after the completion of the rental service.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("lesseeRights")}>{t("Lessee’s Rights and Responsibilities")}</h4>
                    <ul style={{ maxHeight: expansionStates["lesseeRights"] ? '2200px' : '0' }}>
                        <li style={{fontWeight: "600"}}>{t("5.1. The Lessee has the right to")}:</li>
                        <li>{t("5.1.1. Use the Scooter within the Green Zone and beyond.")}</li>
                        <li>{t("5.1.2. Request the Lessor to block the Lessee’s Account, confirming the blocking with the Lessee’s authorization code.")}</li>
                        <li>{t("5.1.3. Request the Lessor to unlock the Lessee’s Account, confirming the activation (unlocking) with the Lessee’s authorization code.")}</li>
                        <li style={{fontWeight: "600"}}>{t("5.2. The Lessee’s responsibilities")}:</li>
                        <li>{t("5.2.1. The Lessee assumes full financial responsibility and liability for their actions, life, health, and damages caused to third parties and the Lessor while using the Scooter.")}</li>
                        <li>{t("5.2.2. Before using the Scooter, the Lessee independently ensures that they are in the appropriate physical and mental state to use the Scooter, and that they have the knowledge and skills required for its use. For their safety, it is recommended and the Lessee has the right to use protective equipment (helmet, protective clothing), which is solely the responsibility of the Lessee to provide at their own expense.")}</li>
                        <li>{t("5.2.3. If the Lessee takes the Scooter for use or discovers technical imperfections/damages during its use, they must immediately inform the Lessor using the Application.")}</li>
                        <li>{t("5.2.4. During the use of the Scooter, the Lessee must adhere to road traffic safety regulations. In the event of a road traffic accident involving the Lessee and/or the Scooter during its use, the Lessee is obliged to immediately report the road traffic accident to the Lessor and the police.")}</li>
                        <li>{t("5.2.5. While moving on sidewalks during the use of the Scooter, the Lessee must choose a speed that does not endanger or hinder pedestrians. If necessary, they must ride at a speed not exceeding the walking speed of pedestrians.")}</li>
                        <li>{t("5.2.6. If the battery of the Scooter depletes during its use, the Lessee is required to stop using the Scooter, confirm the completion of the Scooter rental in the CHARGE Application, and place the Scooter in the Green Zone.")}</li>
                        <li>{t("5.2.7. If the Lessee does not return the Scooter to the Green Zone, a fee is calculated and deducted from the Lessee in the actual amount of the costs incurred, up to EUR 20.00 per incident, for locating and placing the Scooter.")}</li>
                        <li>{t("5.2.8. If the Lessee does not confirm the completion of the Scooter Rental Service in the CHARGE Application within 24 hours from the moment the Scooter was activated, the Lessor considers the Scooter to be lost or stolen, and the Lessee compensates the Lessor for the value of the Scooter and expenses in the amount of EUR 900.00, which the Lessor has the right to deduct from the Lessee’s payment card. The Lessor reports the fact of the Scooter’s loss/theft to the police.")}</li>
                        <li>{t("5.2.9. The Lessee agrees to pay fines, expenses, and other payments incurred by the Lessor and imposed by third parties on the Lessor while the Lessee uses the Scooter and does not comply with the binding regulatory acts in force in the Republic of Latvia, including the regulations of the city municipality of Valmiera. For the administration of fines, expenses, and other payments, the Lessee additionally pays the Lessor a service fee for the administration of fines, expenses, and other payments up to EUR 20.00 per incident.")}</li>
                        <li>{t("5.2.10. The Lessee is responsible for damages, losses, and safety issues that may arise to the Lessee or others while using the Scooter.")}</li>
                        <li>{t("5.2.11. The Lessee is responsible for the Scooter from the moment they take it for use until the moment they confirm the completion of the Scooter Rental Service in the CHARGE Application.")}</li>
                        <li>{t("5.2.12. The Lessee is fully responsible for all damages/losses caused to the Scooter during the Scooter Rental Service.")}</li>
                        <li>{t("5.2.13. If the Scooter is stolen during the rental period, the Lessee must immediately notify the Lessor and the police of the theft.")}</li>
                        <li>{t("5.2.14. The Lessee is responsible for the accuracy and updating of the payment card data issued by their banking institution if changes occur. The Lessee agrees to the Lessor’s right to process all data received from the Lessee.")}</li>
                        <li>{t("5.2.15. The Lessee is obliged to immediately inform the Lessor if they become aware of the unlawful use of the Lessee’s Account and the Lessee’s data, which have been transferred to the Lessor, without the Lessee’s consent.")}</li>
                        <li>{t("5.2.16. The Lessee is aware that using the Scooter involves risks, including risks to the Lessee’s life and health, which in the event of their realization may cause harm to the Lessee’s health, life, and property. The Scooter is a technical device, and its use may involve a technical error that can cause losses, damages, and risks to the Lessee while using the Scooter. During the use of the Scooter, it is possible to cause damages, losses, and injuries to third parties’ health, life, and property, therefore the Lessee must be especially vigilant and use the Scooter as a careful and prudent owner.")}</li>
                        <li>{t("")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("lessorRights")}>{t("Lessor’s Rights and Responsibilities")}</h4>
                    <ul style={{ maxHeight: expansionStates["lessorRights"] ? '1200px' : '0' }}>
                        <li style={{fontWeight: "600"}}>{t("6.1. Lessor’s Rights")}:</li>
                        <li>{t("6.1.1. The Lessor processes data received from the Lessee in the CHARGE Application.")}</li>
                        <li style={{fontWeight: "600"}}>{t("6.1.2. The Lessor has the right to unilaterally and immediately terminate the provision of Services if")}:</li>
                        <li>{t("6.1.2.1. The Lessee has provided the Lessor with false/incomplete information.")}</li>
                        <li>{t("6.1.2.2. The Lessee damages the Lessor’s property, i.e., the Scooter.")}</li>
                        <li>{t("6.1.2.3. The Lessee has a debt to the Lessor.")}</li>
                        <li>{t("6.2. Upon unilateral termination of the Service provision by the Lessor, the Lessee is obliged to immediately make full payment for the Services.")}</li>
                        <li>{t("6.3. Payment for the Services provided by the Lessor to the Lessee will be made using the funds available on the payment card issued by the bank specified by the Lessee. If there are not sufficient funds on the Lessee’s payment card to fully meet their financial obligations to the Lessor, the Lessor has the right to transfer the Lessee’s data to third parties for debt collection or to independently perform actions for debt collection from the Lessee.")}</li>
                        <li style={{fontWeight: "600"}}>{t("6.4. The Lessor has the right to close the Lessee’s Account if")}:</li>
                        <li>{t("6.4.1. The Lessee uses the Lessor’s property, i.e., the Scooter, for unintended purposes.")}</li>
                        <li>{t("6.4.2. The Lessee damages the Scooter.")}</li>
                        <li>{t("6.4.3. The Lessee has given the Scooter for use to another person.")}</li>
                        <li>{t("6.4.4. The Lessee has not returned the Scooter to the Lessor using the CHARGE Application.")}</li>
                        <li>{t("6.4.5. The Lessee has provided the Lessor with false and/or incomplete information.")}</li>
                        <li>{t("6.4.6. The Lessee has provided the Lessor with another person’s information/data for receiving the Service.")}</li>
                        <li>{t("6.4.7. The Lessor suspects the Lessee of fraudulent activities.")}</li>
                        <li>{t("6.4.8. The Lessor does not have valid data from the payment card issued by the bank, with which the Lessee is entitled to make payments for the Services.")}</li>
                        <li>{t("6.5. The Lessor has the right to deactivate and close the Lessee’s Account if it has not been used for more than 12 (twelve) months. The balance of prepaid Services available in the Application is irrevocably deleted and not refunded.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("rentalDuration")}>{t("Scooter Rental Duration")}</h4>
                    <ul style={{ maxHeight: expansionStates["rentalDuration"] ? '1000px' : '0' }}>
                        <li>{t("7.1. The Scooter rental duration begins the moment the Lessee activates the Scooter by scanning its QR code or manually entering the Scooter code.")}</li>
                        <li>{t("7.2. The completion of the Scooter Rental Service is carried out by the Lessee by placing the Scooter in the Green Zone, photographing the Scooter after its placement, uploading this image, and confirming the completion of the Scooter Rental Service in the CHARGE Application. The maximum continuous rental duration of the Scooter is 24 (twenty-four) hours from the moment the Lessee activates the Scooter.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("rentalFee")}>{t("Rental Fee")}</h4>
                    <ul style={{ maxHeight: expansionStates["rentalFee"] ? '1000px' : '0' }}>
                        <li>{t("8.1. The pricing for the Scooter Rental Service is available in the CHARGE Application.")}</li>
                        <li>{t("8.2. By confirming the use of the Scooter, the Lessee affirms that they are familiar with the applicable rental pricing for the Scooter.")}</li>
                        <li>{t("8.3. The rental fee for the Scooter rental is calculated for the entire duration of the Scooter rental, i.e., from the moment the Lessee activates the Scooter until the moment the Lessee confirms the end of the Scooter ride in the Application.")}</li>
                        <li>{t("8.4. The final rental fee amount for the Scooter rental is determined according to the duration of the Scooter rental, and upon the Lessee confirming the completion of the Scooter Rental Service, it is indicated in the CHARGE Application.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("paymentProc")}>{t("Payment Procedure for the Rental Fee")}</h4>
                    <ul style={{ maxHeight: expansionStates["paymentProc"] ? '1200px' : '0' }}>
                        <li>{t("9.1. The Lessee makes the payment for the rental fee using a payment card issued by a bank. Payment of the rental fee with a bank-issued payment card requires prior activation of the payment card data in the CHARGE App by the Lessee and the Lessee’s agreement to the Terms and Privacy Policy.")}</li>
                        <li>{t("9.2. By entering the payment card data issued by a bank in the CHARGE App, the Lessee agrees and grants the Lessor the right to receive, by automatically debiting, from the payment card specified in the CHARGE App by the Lessee, all payments for the Scooter rental, expenses, including fines, and losses caused by the Lessee to the Lessor in connection with the use of the Scooter.")}</li>
                        <li>{t("9.3. The Lessee is responsible for ensuring that the balance on the payment card specified in the CHARGE App is sufficient for the payment of the Lessor’s Services. At the start of a ride, a balance check of the Lessee’s payment card will be performed, and a reservation of funds in the amount of 2 EUR will be made. The final rental fee amount for the Scooter rental is determined according to the duration of the Scooter rental, and upon the Lessee confirming the completion of the Scooter Rental Service, it is indicated in the CHARGE App.")}</li>
                        <li>{t("9.4. The payment for the service is considered made when it is received in the Lessor’s account.")}</li>
                        <li>{t("9.5. If the Lessee disagrees with the amount of funds transferred from the Lessee’s bank-issued payment card to the Lessor, the Lessee has the right within 10 (ten) days from the date of the funds transfer to submit an application to the Lessor, stating arguments and evidence supporting the disputed amount of the payment made in favor of the Lessor from the Lessee’s bank-issued payment card.")}</li>
                        <li>{t("9.6. If the Lessee does not properly fulfill the Agreement and/or provides incorrect confirmations, along with other losses and costs, the Lessee must compensate CHARGE for losses and costs incurred in connection with debt collection or other actions related to ensuring the fulfillment of the Agreement’s terms, including, but not limited to, fees for legal services and debt collection costs.")}</li>
                        <li>{t("9.7. CHARGE has the right to automatically deduct the amounts specified in the Agreement if the Scooter has been damaged due to the Lessee’s fault, and the Lessee has the obligation to cover other losses, costs, and pay fines according to the Agreement. Hereby, the Lessee agrees that CHARGE can reserve funds up to the total estimated cost and loss amount and deduct any amounts that the Lessee owes according to the Agreement from the payment card registered in the CHARGE App. However, if the reservation of funds fails or the previously mentioned payment card does not have sufficient funds, starting a new ride with a CHARGE Scooter is not possible, and the account in the CHARGE App is temporarily blocked until the amount is paid and all settlements for payments calculated according to the Agreement are made.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("payments")}>{t("Payments")}</h4>
                    <ul style={{ maxHeight: expansionStates["payments"] ? '1000px' : '0' }}>
                        <li>{t("10.1. After registration in the CHARGE App, the Lessee needs to add a valid payment card to their profile in the CHARGE App. By adding such a payment card, the Lessee confirms that they have the right to use the card for such purposes and that no other person will claim against the use of this payment card for making payments for CHARGE Services and payments related to this Agreement.")}</li>
                        <li>{t("10.2. The Lessee’s payment card, when added to the CHARGE Application for the first time, will be verified using the bank’s 3D security technology, which confirms the ownership of the card and the card owner’s confirmation to make payments for the Rental Service and other Services related to this Agreement with the added payment card.")}</li>
                        <li>{t("10.3. After adding the payment card, according to these terms, a deposit must be made for the use of CHARGE Services, which can be done by selecting the deposit amount according to one of the deposit amounts indicated in the CHARGE Application. After making the deposit, the available balance will be reflected in the “e-Wallet” section, which can be used to pay for CHARGE services according to the price list indicated in the CHARGE Application.")}</li>
                        <li>{t("10.4. The money deposited by the Lessee using the CHARGE Application is a prepayment for CHARGE Services and cannot be returned.")}</li>
                        <li>{t("10.5. In cases where the calculated price for a ride exceeds the available balance in the “e-Wallet,” the missing amount will be debited from the payment card linked to the account after the ride ends. In such cases, the Lessee must ensure that there are sufficient funds in the payment card account to make this transaction. If this is not done, CHARGE has the right to withhold from the payment card added to the CHARGE Application the money amount equal to the Lessee’s debt for the rides taken, as well as other expenses and/or losses incurred as a result of the Lessee’s actions or inactions.")}</li>
                        <li>{t("10.6. If the Lessee does not have enough funds in the payment card to make the payment, the amount of the Service fee will be withheld and requested again when the money amount is available in the payment card.")}</li>
                        <li>{t("10.7. If the payment card balance is not replenished within 3 days to pay the Service fee, the User’s CHARGE Application account will be blocked.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("contract")}>{t("Contract between the Lessee and the Lessor")}</h4>
                    <ul style={{ maxHeight: expansionStates["contract"] ? '1000px' : '0' }}>
                        <li>{t("11.1. When the Lessee registers in the CHARGE Application and confirms the Terms, it is considered that the Lessor and the Lessee have entered into a Contract. The Contract is in force for an indefinite period. The Contract concluded in the CHARGE Application has the same legal force as a signature on written documents, and they can be used as evidence of obligations.")}</li>
                        <li>{t("11.2. The Contract is terminated by the actions of the Lessor and/or the Lessee, manifested as the closing of the Account.")}</li>
                        <li>{t("11.3. The Contract between the Lessee and the Lessor can be terminated by mutual agreement between the Lessee and the Lessor or unilaterally at any time.")}</li>
                        <li>{t("11.4. The Lessee has the right at any time to delete their account from the CHARGE Application. Account deletion is only possible if there is no active Scooter Rental Service, i.e., the Lessee is not using the Scooter for use, and the Lessee has settled all financial obligations with the Lessor, including the completion of all rental fee payments for possible damages, fines, expenses, and other payments (if any).")}</li>
                        <li>{t("11.5. The Lessor can delete the Lessee’s account at any time if the Lessee has not used the account for more than 12 (twelve) months and the Lessee has fully settled all financial obligations related to the Lessor.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("legislation")}>{t("Legislation and Dispute Resolution")}</h4>
                    <ul style={{ maxHeight: expansionStates["legislation"] ? '1000px' : '0' }}>
                        <li>{t("12.1. The legal relations between the Lessor and the Lessee are subject to the regulatory acts in force in the Republic of Latvia.")}</li>
                        <li>{t("12.2. If any of the Terms' conditions lose force, then this condition is not applicable, but the rest of the Contract’s terms retain their force.")}</li>
                        <li>{t("12.3. The Lessor and the Lessee strive to resolve disputes by mutual agreement based on the principles of fairness, reasonableness, and legality. If a dispute is not resolved within 30 (thirty) days from the date of its occurrence, the dispute is submitted for consideration to the courts of the Republic of Latvia.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("changes")}>{t("Changes to the Terms")}</h4>
                    <ul style={{ maxHeight: expansionStates["changes"] ? '1000px' : '0' }}>
                        <li>{t("13.1. The Lessor has the right to make changes to the Terms at any time without prior notice to the Lessee.")}</li>
                        <li>{t("13.2. The Lessor will inform the Lessee about the updated Terms by email or in the CHARGE Application or will publish the new edition on the CHARGE website.")}</li>
                        <li>{t("13.3. If the Lessee does not wish to adhere to the updated Terms, the Lessee must immediately notify the Lessor of the discontinuation of the Service use from the receipt of the notice about the update of the Terms. If the Lessee has not notified the Lessor of the discontinuation of the Service use, it is considered that the Lessee has accepted the updated Terms.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("contact")}>{t("Contact Information")}</h4>
                    <ul style={{ maxHeight: expansionStates["contact"] ? '300px' : '0' }}>
                        <li>{t("LTD. “ORGANIC BALTICS”, reg. no. 40103570161")}</li>
                        <li>{t("Legal address: Kaņiera street 4C – 45, Riga, LV-1063")}</li>
                        <li>{t("Actual address: Kaņiera street 4C – 45, Riga, LV-1063")}</li>
                        <li>{t("Email: charge@chargemobility.lv")}</li>
                    </ul>
                </section>
            </div>
        {t("")}
        </div>
    );
}
