import SupportMain from "../components/support/supportMain";

function Support() {
    return(
        <>
            <SupportMain />
        </>
    );
}

export default Support;