import { useTranslation } from "react-i18next";
import { useState } from "react";
import "../../terms/terms_use/terms_use.css";

export default function Privacy_policy() {
    const {t} = useTranslation();
    const [expansionStates, setExpansionStates] = useState({});
    const toggleExpand = (id) => {
        setExpansionStates(prev => ({ ...prev, [id]: !prev[id] }));
    };

    return(
        <div className="terms_main">
            <div>
                <h6>{t("This privacy policy applies to and governs the collection, processing, management, and storage of personal data received by SIA “ORGANIC BALTICS”, reg. No. 40103570161, Legal address: Kaņiera street 4C – 45, Riga, LV-1063, hereinafter referred to as the Lessor, from a physical person – hereinafter referred to as the Lessee, who has provided their personal data to the Lessor via the CHARGE application, hereinafter referred to as the Application, for receiving services. For the purposes of this privacy policy, personal data refers to any information relating to the Lessee as an identifiable individual or to the identity of the Lessee.")} </h6>
            </div>
            <div className="tou_list">
                <section>
                    <h4 onClick={() => toggleExpand("dataProc")}>{t("Purpose of Personal Data Processing")}</h4>
                    <ul style={{ maxHeight: expansionStates["dataProc"] ? '1000px' : '0' }}>
                        <li>{t("The primary purpose of processing the Lessee's personal data is to provide services – to accurately reflect information about CHARGE Scooters and the Lessee’s rides in the CHARGE Application. Additionally, personal data processing may be conducted to analyze the performance of CHARGE and ensure its operation and existence, which may include the collection and analysis of statistics. There are binding regulations that may impose an obligation to process data for accounting and tax administration purposes.")}</li>
                        <li>{t("Preventing, detecting, and investigating activities that are prohibited, illegal, or do not conform to good practice, including monitoring, detecting, and preventing criminal offenses, including those related to the Lessor's property, and investigating and analyzing fraudulent payments and incidents objectively;")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("consent")}>{t("Consent for Collection, Processing, Storage, and Use of Personal Data ")}</h4>
                    <ul style={{ maxHeight: expansionStates["consent"] ? '1000px' : '0' }}>
                        <li>{t("By filling in information, including personal data, in the Application, the Lessee consents and agrees that the Lessor may collect, process, store, and/or use the personal data in accordance with this privacy policy.")}</li>
                        <li>{t("The Lessee, by giving their consent to the Lessor for the collection, processing, management, and storage of data, retains the right to correct and delete their personal data submitted to the Lessor. The Lessee has the right to withdraw consent for data processing at any time (if consent is the basis for data processing according to the policy) by sending a written request to the Lessor. The Lessee’s consent is considered received when the Lessee has confirmed their acquaintance with the privacy policy in the Application. Withdrawing consent does not stop data processing that the Lessor performs based on other legal grounds.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("personalData")}>{t("Lessee's Personal Data")}</h4>
                    <ul style={{ maxHeight: expansionStates["personalData"] ? '1000px' : '0' }}>
                        <li>{t("By establishing legal relations with the Lessor, the Lessee agrees that the following personal data for identifying the Lessee are collected, processed, and managed: name, surname, telephone number, website URL, IP address, geographical location, registration date and time, browser used, email address, residential address, data of electronic devices used to access the Application, payment card details, including payment card number, issue date, expiry date, CVV, payment card issuer, etc., submitted by the Lessee.")}</li>
                        <li>{t("The Lessor collects, compiles, processes, manages, and stores the Lessee's data submitted during the installation and use of the Application. The Lessee’s personal data regarding their location is obtained and processed only when the Application is activated by the Lessee. Location data collection stops when the Lessee closes the Application.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("legalBasis")}>{t("Legal Basis for Personal Data Processing")}</h4>
                    <ul style={{ maxHeight: expansionStates["legalBasis"] ? '1000px' : '0' }}> 
                        <li style={{fontWeight: "600"}}>{t("The Lessor processes the Lessee's personal data based on the following legal grounds:")}</li>
                        <li>{t("Establishing and fulfilling legal relations – to provide rental services upon the Lessee's request; Compliance with regulatory acts – to fulfill obligations stipulated in external regulations binding on the Lessor; Based on the consent of the Lessee – data subject; For legitimate interests – to realize interests arising from the obligations between the Lessor and the Lessee or legal interests of the Lessor resulting from law; The Lessor’s legitimate interests include conducting business activities, providing rental services, verifying the identity of the Lessee before providing services, ensuring the fulfillment of obligations, preventing unjustified financial risks to its business, including performing a credit risk assessment before and during service provision, maintaining applications for services, notes on them, other submissions, complaints, claims, and requests; conducting recorded negotiations with the Lessee for the purpose of service operation, maintenance, settlements, to perform service quality control; managing the Lessee’s user account in the Application; advertising the Lessor's services; sending messages about service provision and information on other significant events; ensuring corporate governance, financial and business accounting, and analytics; improving service quality; managing payments; managing non-payments; addressing public administration and operational activities authorities and the court to protect its legal interests.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("personalProtection")}>{t("Personal Data Processing and Protection")}</h4>
                    <ul style={{ maxHeight: expansionStates["personalProtection"] ? '1000px' : '0' }}>
                        <li>{t("The Lessor processes and protects the processed data of the Lessee from distortion, loss, destruction, or access by unauthorized persons, using modern technology options and organizational, financial, and technical resources reasonably available to the Lessor.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("categories")}>{t("Categories of Personal Data Recipients ")}</h4>
                    <ul style={{ maxHeight: expansionStates["categories"] ? '1000px' : '0' }}>
                        <li style={{fontWeight: "600"}}>{t("The Lessor does not disclose the Lessee's personal data or any information obtained during service provision to third parties, except")}:</li>
                        <li>{t("If such data must be disclosed to a third party within the framework of a contract concluded by the Lessor to perform a function necessary for providing a service to the Lessee (for example, to a bank for settlements, an information technology company for client data processing, an accounting service provider for processing settlement information, etc.),")}</li>
                        <li>{t("According to the clear and unambiguous consent of the Client,")}</li>
                        <li>{t("In the manner prescribed by the regulations in force in the Republic of Latvia to persons who have made a legally justified request to the Lessor and who have a legal basis for receiving the Lessee's personal data from the Lessor,")}</li>
                        <li>{t("To protect the Lessor's legitimate interests, including but not limited to, addressing the court or other state institutions against a person who has infringed the Lessor's legitimate interests,")}</li>
                        <li>{t("To develop special offers tailored to the Client's interests and to improve the Application's performance.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("dataStorage")}>{t("Personal Data Storage Period ")}</h4>
                    <ul style={{ maxHeight: expansionStates["dataStorage"] ? '1000px' : '0' }}>
                        <li style={{fontWeight: "600"}}>{t("The Lessor stores personal data as long as the Lessee's user account is active. If the Lessee's user account is closed, personal data are deleted from databases, except in cases where such data storage is necessary for accounting, dispute resolution, or fraud prevention in the following order:")}</li>
                        <li>{t("Recordings of conversations with the Lessee – deleted six months after the date of recording.")}</li>
                        <li>{t("Data on services provided to the Lessee – deleted five years after the date of the last service provided.")}</li>
                        <li>{t("History of conducted rides data – deleted five years after the date of the last ride conducted.")}</li>
                        <li>{t("In cases of suspicion of a criminal offense, fraud attempt, or provision of false information – personal data are deleted ten years after the date of the last service provided.")}</li>
                        <li>{t("In cases of disputes related to payments, data will be stored as long as the claim is satisfied or until the end of the dispute claim period.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("rightToAccess")}>{t("Lessee's Rights to Access Personal Data")}</h4>
                    <ul style={{ maxHeight: expansionStates["rightToAccess"] ? '1000px' : '0' }}>
                        <li>{t("The Lessee has the right to receive information prescribed by regulatory acts regarding their data processing by sending a written request to the Lessor.")}</li>
                        <li>{t("The Lessee has the right to request the Lessor to provide access to their personal data, and also the right to request the Lessor to supplement, correct, or delete their personal data, or to restrict processing, or the right to object to the processing of their personal data. The Lessee exercises their rights by submitting a request to the Lessor in written form. Upon receiving the written request, the Lessor verifies the identity of the Lessee, evaluates the request, and fulfills it according to the regulations in force in the Republic of Latvia. The Lessor sends a written response to the Lessee to the address indicated in the written request or to the address provided by the Lessee to the Lessor.")}</li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("communication")}>{t("Lessee's Rights and Communication of Information")}</h4>
                    <ul style={{ maxHeight: expansionStates["communication"] ? '1000px' : '0' }}>
                        <li>{t("The Lessor uses the contact information provided by the Lessee, such as telephone number, email address, for communication with the Lessee. The Lessee has the right to opt out of receiving commercial communications, and they will have the opportunity to refuse to receive further commercial communications.")} </li>
                        <li>{t("Also, the Lessee has the right to withdraw their consent to the processing of location data at any time, which can be done in their device settings.")} </li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("controler")}>{t("Controller of Personal Data")}</h4>
                    <ul style={{ maxHeight: expansionStates["controler"] ? '1000px' : '0' }}>
                        <li>{t("The controller of personal data processing is LTD “Organic Baltics”, reg. No. 40103570161, Legal address: Kaņiera street 4C-45, Riga. Actual address: Kaņiera street 4C-45, Riga (Lessor).")} </li>
                        <li>{t("Contact information of the Lessor related to personal data processing is: charge@chargemobility.lv")} </li>
                    </ul>
                </section>
                <section>
                    <h4 onClick={() => toggleExpand("changes")}>{t("Changes to the Privacy Policy")}</h4>
                    <ul style={{ maxHeight: expansionStates["changes"] ? '1000px' : '0' }}>
                        <li>{t("The Lessor reserves the right to make unilateral amendments to the privacy policy. Any updated edition of the Privacy Policy is considered notified if information about it is indicated in the CHARGE Application and information about the changes has been sent to the Lessee's specified email. Also, the updated edition will be published in the CHARGE Application.")}</li>
                    </ul>
                </section>
            </div>
        </div>
    );
}