import { useTranslation } from "react-i18next";
import Down_butt from "../../general/down_butt/down_butt";
import "../../locations/locations_hero/repetitive_hero.css";

export default function Taxi_hero() {
    const { t } = useTranslation();

    return(
        <>
            <div className="container-fluid repetitive_hero">
                <div className="row">
                    <div className="col-lg-6 col-md-12 rh_left">
                        <div className="taxi_hero_h1">
                            <h1>{t("Welcome to CHARGE Taxi — Where Every Journey is an Experience")} </h1>
                            <h6>{t("Forget the hassle of conventional commuting and dive into the comfort and convenience of CHARGE Rides, your premier ride-and-hail service. At CHARGE, we believe in the power of connection, not just between places, but between people. Whether you're rushing to a business meeting, gearing up for a night out, or just exploring the city, our service is tailored to make your journey smooth, enjoyable, and absolutely effortless.")} </h6>
                            <div>
                                <Down_butt />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 rh_right">
                        <div>
                            <img src="/taxi_imgs/taxi1.png" alt="taxi1" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}