import React from 'react';
import { useTranslation } from "react-i18next";
import Down_butt from "../../components/general/down_butt/down_butt";
import ContactForm from "../general/contact_form/contactForm";
import "./supportMain.css";

export default function SupportMain() {
    const { t } = useTranslation();

    return (
        <>
            <div className="support_main">
                <div className="support_hero">
                    <div className="container-fluid repetitive_hero">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 rh_left">
                                <div className="taxi_hero_h1">
                                    <h1>{t("Welcome to CHARGE Support")}</h1>
                                    <h2>{t("We're Here to Help!")}</h2>
                                    <h6>{t("At CHARGE, we’re committed to providing you with exceptional support whether you’re a rider, a franchisee, or someone interested in learning more about our services. Find the help you need with our resources, contact options, and community support.")}</h6>
                                    <div>
                                        <Down_butt /> {/* Correct usage */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 rh_right">
                                <div>
                                    <img src="/slider/slider1.png" alt="slider1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="support_troubleshoot container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 support_ts_item1">
                            <div className="support_ts_item1_list1">
                                <h2>{t("Troubleshooting Guide")} </h2>
                                <h4>{t("Common Issues and Quick Fixes:")} </h4>
                                <ul>
                                    <li> <span>{t("Scooter won’t start:")} </span>{t("Ensure that the scooter is adequately charged and that you are in an area with good network coverage.")} </li>
                                    <li> <span>{t("App issues:")} </span>{t("Try restarting the app or reinstalling if persistent problems occur.")} </li>
                                    <li> <span>{t("Payment problems:")} </span>{t("Double-check your payment information and ensure there are sufficient funds.")} </li>
                                </ul>
                            </div>
                            <div className="support_ts_item1_list3">
                                <h2>{t("Contact Us")} </h2>
                                <h4>{t("Need more assistance? Get in touch!")} </h4>
                                <ul>
                                    <li><span>{t("Email:")} </span> support@chargemobility.eu </li>
                                    <li><span>{t("Phone:")} </span> +371 2 77 64 888 </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 support_ts_item2">

                        </div>
                    </div>
                </div>
                    <div className="support_form">
                        <ContactForm formHeading={t("Feedback and Suggestions")}> 
                            <p> <span>{t("We Value Your Input")} </span>{t(" Your feedback drives our improvement. Please let us know how we can better your experience with CHARGE by contacting us via email or through our feedback form on the website.")} </p>
                        </ContactForm>
                    </div>
            </div>
        </>
    );
}
