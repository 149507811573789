import Safety_hero from "../components/safety/safety_hero/safety_hero";

function Safety() {
    return(
        <>
            <Safety_hero />
        </>
    );
}

export default Safety;