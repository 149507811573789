import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Franchise_hero from "../components/franchise/franchise_hero/franchise_hero";
import ContactForm from "../components/general/contact_form/contactForm";
import Franchise_Numbers from "../components/franchise/franchise_numbers/franchise_number";
import Franchise_section from "../components/franchise/franchise_section/franchise_section";
import Franchise_call from "../components/franchise/franchise_call/franchise_call";
import Franchise_shouter from "../components/franchise/franchise_shouter/franchise_shouter";
import Franchise_faq from '../components/franchise/franchise_faq/franchise_faq';

function Franchise() {
    const location = useLocation();
    useEffect(() => {
        if (location.state?.scrollToContact) {
            const element = document.getElementById('contactForm');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [location.state]);

    return(
        <>
            <Franchise_hero />
            <Franchise_section />
            <Franchise_call />
            <Franchise_Numbers />
            <Franchise_shouter />
            <div id="contactForm">
                <ContactForm />
            </div>
            <Franchise_faq />
        </>
    );
}

export default Franchise;
