import Locations_hero from "../components/locations/locations_hero/locations_hero";
import Locations_places from "../components/locations/locations_places/locations_places";

function Locations() {
    return(
        <>
            <Locations_hero />
            <Locations_places />
        </>
    );
}
export default Locations;