import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';
import "./contactForm.css";

const ContactForm = ({formHeading, children}) => {
    const { t } = useTranslation();
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_azm4xpq', 'template_yqepsnm', form.current, 'wLz1dKKLXfb-ZWMQO')
            .then((result) => {
                console.log(result.text);
                alert("Form successfully submitted. We will reach out to you as soon as possible!");
                const formData = new FormData(form.current);
                const data = {
                    email: formData.get('email'),
                    firstName: formData.get('firstName'),
                    lastName: formData.get('lastName'),
                    country: formData.get('country'),
                    message: formData.get('message')
                };
            }, (error) => {
                console.log(error.text);
                alert("Failed to send form. Please try again.");
            });
    };

    return (
        <div id='form_main'>
            <div className='form_shout'>
                <h2>{formHeading || t("Take the first step towards electrifying your future. Contact us now to explore this exciting opportunity!")}</h2>
                {children}
            </div>
            <div id='contact_form'>
                <form ref={form} onSubmit={sendEmail}>
                    <div className='form_email'>
                        <label>Your e-mail: <br />
                            <input type="email" name="email" placeholder="example@email.com" autoComplete="email" required />
                        </label>
                    </div>
                    <div className='form_name'>
                        <label>Your first name: <br />
                            <input type="text" name="firstName" placeholder="John" autoComplete="given-name" required />
                        </label>
                    </div>
                    <div className='form_surname'>
                        <label>Your last name: <br />
                            <input type="text" name="lastName" placeholder="Doe" autoComplete="family-name" required />
                        </label>
                    </div>
                    <div className='form_country'>
                        <label>Country: <br />
                            <input type="text" name="country" placeholder="Latvia" autoComplete="country-name" required />
                        </label>
                    </div>
                    <div className='form_message'>
                        <label>Your message: <br />
                            <input type="text" name="message" placeholder="Tell us about yourself" autoComplete="off" required />
                        </label>
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default ContactForm;
