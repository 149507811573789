import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import "./faq.css";

function Faq() {
    const { t } = useTranslation();
    const [visibleSections, setVisibleSections] = useState({});
    const toggleSectionVisibility = (id) => {
        setVisibleSections(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    
    return (
        <>
            <div className="container-fluid" id="faq">
                <div className="row">
                    <div className="col-lg-6 faq_left">
                        <h2>{t("Frequently asked questions")}</h2>
                    </div>
                    <div className="col-lg-6 faq_right">
                        <div>
                            <section>
                                <h4 onClick={() => toggleSectionVisibility('faq1')} aria-expanded={visibleSections['faq1'] ? "true" : "false"}>
                                    {t("How do I start using CHARGE scooters?")}
                                </h4>
                                <p style={{ maxHeight: visibleSections['faq1'] ? '500px' : '0px', overflow: 'hidden' }}>
                                    {t("To start using CHARGE scooters, follow these simple steps:")} <br /> <br />
                                    <span>{t("1. Download the CHARGE App")} : </span> {t("Available on both Android and iOS, the app is your gateway to accessing our scooters.")} <br /> <br />
                                    <span>{t("2. Create an Account")} : </span> {t("Sign up with your email and provide the necessary information, such as your payment method.")} <br /> <br />
                                    <span>{t("3. Find a Scooter")} : </span> {t("Use the app to locate available scooters near you.")} <br /> <br />
                                    <span>{t("4. Unlock and Go")} : </span> {t("Scan the QR code on the scooter to unlock it, then you’re ready to ride!")} <br /> <br />
                                    <span>{t("5. Park Responsibly")} : </span> {t("Once you’ve reached your destination, park the scooter in a designated area, and end your session in the app.")}
                                </p>
                            </section>
                            <section>
                                <h4 onClick={() => toggleSectionVisibility('faq2')} aria-expanded={visibleSections['faq2'] ? "true" : "false"}>
                                    {t("How can I find a nearby scooter?")}
                                </h4>
                                <p style={{ maxHeight: visibleSections['faq2'] ? '500px' : '0px', overflow: 'hidden' }}>
                                    {t("To find a nearby CHARGE scooter:")} <br /> <br />
                                    <span>{t("1. Open the CHARGE App")} : </span> {t("Once you’ve logged in, you'll see a map showing all available scooters in your vicinity.")} <br /> <br />
                                    <span>{t("2. Search Functionality")} : </span> {t("Use the search feature to find scooters in specific areas or near landmarks.")} <br /> <br />
                                    <span>{t("3. Real-Time Availability")} : </span> {t("The app provides real-time updates on scooter availability to ensure you can find a ride when you need one.")}
                                </p>
                            </section>
                            <section>
                                <h4 onClick={() => toggleSectionVisibility('faq3')} aria-expanded={visibleSections['faq3'] ? "true" : "false"}>
                                    {t("What should I do if I encounter a problem with a scooter?")}
                                </h4>
                                <p style={{ maxHeight: visibleSections['faq3'] ? '500px' : '0px', overflow: 'hidden' }}>
                                    {t("If you encounter a problem with a scooter, please follow these steps:")} <br /> <br />
                                    <span>{t("1. Report Immediately")} : </span> {t("Use the CHARGE app to report the issue. There's an option in the ride menu to “Report a Problem” where you can describe what’s wrong.")} <br /> <br />
                                    <span>{t("2. Contact Support")} : </span> {t("If the issue needs immediate attention or you can’t solve it through the app, please call our support team at ")} +371 2 77 64 888 <br /> <br />
                                    <span>{t("3. Safety First")} : </span> {t("If the scooter is unsafe to ride, please do not attempt to use it. Report the scooter as damaged in the app, and then look for another nearby scooter to use.")}
                                </p>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify({
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [{
                        "@type": "Question",
                        "name": "Can I rent multiple scooters?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, you can press the 'Add another' button after starting a ride to add more."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "Can I pause my ride?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, you can press the 'stop ride' at the bottom of your screen. 'Pause ride' option will appear. The vehicle will be locked and you can leave it until you want to continue your ride. Pausing your ride costs per minute and may vary depending on the location."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How much does it cost to ride?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "There is a starting fee to unlock the vehicle as well as a fee per minute used. Press on a scooter in the app to see the pricing. It may vary between locations."
                        }
                    },
                    {
                        "@type": "Question",
                        "name": "How do I start riding?",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Find a Hopp vehicle on the map, unlock it by tapping the 'Ride' button, then either scan the QR code or enter the number below the QR code. You can turn on the flashlight of your phone while scanning in case it's dark out."
                        }
                    }
                ]
            }) }} />
        </>
    );
}

export default Faq;
