import { useTranslation } from "react-i18next";
import { useEffect, useRef } from 'react';
import "./franchise_call.css";

export default function Franchise_call() {
    const { t } = useTranslation();
    const dotRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (dotRef.current) {
                const dotYPosition = dotRef.current.getBoundingClientRect().top + window.scrollY;
                const liElements = document.querySelectorAll('.fcm_right_hover ul li');

                liElements.forEach(li => {
                    const liTop = li.getBoundingClientRect().top + window.scrollY;
                    const liBottom = liTop + li.getBoundingClientRect().height;
                    if (liTop <= dotYPosition && liBottom >= dotYPosition) {
                        li.style.opacity = 1;
                    } else {
                        li.style.opacity = 0.5;
                    }
                });
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return(
        <div className="franch_call_main">
            <section>
                <div className="fcm_left">
                    <div className="fcm_left_sticky">
                        <h2 className="fcm_sticky_title">{t("Why CHARGE?")} <br /> <span>{t("Because we offer:")}</span> </h2>
                    </div>
                </div>
                <section>
                <div className="fcm_mid_line">
                    <div>
                        <div className="fcm_mid_dot" ref={dotRef}>
                            &#11044;
                        </div>
                    </div>
                </div>
                <div className="fcm_right">
                    <div className="fcm_right_hover">
                        <ul>
                            <li>
                                <span>{t("Cutting-Edge Technology: ")}</span> <br />
                                {t("Our scooters are equipped with the latest in eco-friendly transportation technology, ensuring a smooth and efficient ride for every user.")} 
                            </li>
                            <li>
                                <span>{t("Robust Support System: ")}</span> <br />
                                {t("From operational training to marketing campaigns, we provide comprehensive support to ensure your success. With CHARGE, you're never alone.")}
                            </li>
                            <li>
                                <span>{t("Sustainability: ")}</span> <br />
                                {t("Be a part of the solution, not the pollution. Our business model prioritizes environmental stewardship, making every ride a step towards a cleaner planet.")}
                            </li>
                            <li>
                                <span>{t("High Growth Potential: ")}</span> <br />
                                {t("The demand for convenient, eco-friendly transportation is skyrocketing. With CHARGE, tap into a booming market and ride the wave of success.")}
                            </li>
                            <li>
                                <span>{t("Community Impact: ")}</span> <br />
                                {t("Make a tangible difference in your city by offering a fun, affordable, and green way to travel. With CHARGE, you bring communities closer, one ride at a time.")}
                            </li>
                        </ul>
                    </div>
                </div>
                </section>
            </section>
        </div>
    );
}
