import { useTranslation } from "react-i18next";
import Down_butt from "../../general/down_butt/down_butt";
import MySlider from "../../general/slider/slider";
import "./about_mission.css";

export default function About_mission() {
    const {t} = useTranslation();

    return(
        <>
            <div className="about_m_main">
                <div className="amm_first">
                    <div className="amm_f_title">
                        <h4>{t("Welcome to CHARGE")}</h4>
                        <h3>{t("where mobility meets innovation and convenience.")}</h3>
                    </div>
                    <div className="amm_f_mission">
                        <h2>{t("At CHARGE, we are dedicated to transforming urban transportation, making it more accessible, efficient, and environmentally friendly.")}</h2>
                        <h3>{t("Our mission is to empower people to navigate their cities in the smartest way possible.")}</h3>
                    </div>
                </div>
                <div className="amm_title_two">
                    <h6>{t("Our Services")}</h6>
                    <h3>{("CHARGE offers a comprehensive range of micromobility services tailored to the needs of modern urbanities. Our fleet of cutting-edge scooters and seamless ride-and-hail services are available through our user-friendly app, ensuring you can get where you need to go with ease and style.")} </h3>
                </div>
                <div className="container-fluid amm_services">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 amm_services_item">
                            <p>{t("Experience the freedom of zipping through city streets with CHARGE’s top-of-the-line electric scooters. Perfect for your daily commute, running errands, or just enjoying a leisurely ride around town, our scooters are safe, reliable, and always ready to go.")}</p>
                        </div>
                        <div className="col-lg-6 col-md-12 amm_services_item">
                            <img src="/slider/slider1.png" alt="amm_1" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12 amm_services_item">
                            <img src="/slider/slider2.png" alt="amm_2" />
                        </div>
                        <div className="col-lg-6 col-md-12 amm_services_item">
                            <p>{t("Our ride-and-hail services merge convenience with efficiency. Whether you need a quick lift or a more personalized travel solution, CHARGE is just a tap away. Our responsive app ensures that your transportation is as flexible as your day demands, with real-time tracking and support.")}</p>
                        </div>
                    </div>
                </div>
                <div className="amm_commit">
                    <h6>{t("Our Commitment to Sustainability")}</h6>
                    <h4>{t("At CHARGE, we believe in the power of clean energy to create a more sustainable future. Our services are designed to reduce urban congestion and pollution, promoting healthier communities and a healthier planet.")} </h4>
                </div>
                <div className="container-fluid amm_call_line">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <p>{t("Get our app today and join Sustainability oriented community!")} </p>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <Down_butt />
                        </div>
                    </div>
                </div>
                <div className="amm_journey">
                    <h6>{t("Join Us on the Journey")}</h6>
                    <h4>{t("With CHARGE, experience the joy of movement that is swift, sustainable, and smart. Download our app today and take your first step towards a more connected and mobile future. Let’s ride into a cleaner, greener tomorrow together.")}</h4>
                </div>
                <MySlider />
            </div>
        </>
    );
}