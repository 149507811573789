import { useTranslation } from "react-i18next";
import "./franchise_number.css";
import { useState, useEffect } from 'react';

function Franchise_Numbers() {
    const { t } = useTranslation();
    const [scooterCount, setScooterCount] = useState(100);
    const [investment, setInvestment] = useState(109595);
    const [revenue, setRevenue] = useState(296400);

    useEffect(() => {
        const newInvestment = 889 * (scooterCount / 1);
        setInvestment(newInvestment);
        const newRevenue = 1606 * (scooterCount / 1);
        setRevenue(newRevenue);
    }, [scooterCount]);

    const decreaseCount = () => {
        setScooterCount(prevCount => Math.max(30, prevCount - 10));
    };

    const increaseCount = () => {
        setScooterCount(prevCount => Math.min(500, prevCount + 10));
    };

    const formatNumber = (number) => {
        const numberFormatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
        });
        return numberFormatter.format(number);
    };

    return(
        <div className="container-fluid franch_num_main">
            <div className="row">
                <div className="col-lg-6 col-sm-12 frnum_left">
                    <div>
                        <h2>{t("Why open Charge franchise?")}</h2>
                        <h6>{t("Become a part of CHARGE – where every ride powers a greener tomorrow. Use preliminary calculation of investments.")}</h6>
                    </div>
                    <div>
                        <p>{t("These estimates is based on real usage data from Charge's operations, when each scooter get's 5 rides per day and each ride lasting for 8 minutes.")}</p>
                        <p>{t("* Revenue assumes: 24/7/365 days availability €0.80 Unlock fee and €0.20/min fee.")}</p>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 frnum_right">
                    <div className="frnum_r_scooter">
                        <p>Scooters</p>
                        <div>
                            <button onClick={decreaseCount}>-</button>
                            <span className="scooter_span">{scooterCount}</span>
                            <button onClick={increaseCount}>+</button>
                        </div>
                    </div>
                    <div className="frnum_r_invest">
                        <p>Investment</p>
                        <span className="invest_span">${formatNumber(investment)}</span>
                    </div>
                    <div className="frnum_r_rev">
                        <p>Year-over-year Revenue*</p>
                        <span className="rev_span">${formatNumber(revenue)}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Franchise_Numbers;
