import { useTranslation } from "react-i18next";
import "./franchise_shouter.css";
import Down_butt from "../../general/down_butt/down_butt";

export default function Franchise_shouter() {
    const {t} = useTranslation();

    return(
        <div className="franchise_shouter_main">
            <div className="fsm_text">
                <div>
                    <p>{t("This is your moment to shine in the fast-growing world of scooter sharing. Be a leader in your community, champion sustainability, and drive towards a profitable future. Join CHARGE today and let's redefine urban mobility together.")} </p>
                </div>
                <div>
                    <Down_butt />
                </div>
            </div>
        </div>
    );
}