import Taxi_hero from "../components/taxi/taxi_hero/taxi_hero";
import Taxi_benefits from "../components/taxi/taxi_benefits/taxi_benefits";

function Taxi() {
    return(
        <>  
            <Taxi_hero />
            <Taxi_benefits />
        </>
    );
}

export default Taxi;